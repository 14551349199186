const global = {
    HRP_COLOR_M: '#3366CC',
    HRP_COLOR_S1: '#EEAA00'
}
function bindToWindow (obj) {
    window.const = {}
    window.const.global = {}
    for (var key in obj) {
        window.const.global[key] = obj[key]
    }
}
bindToWindow(global)
