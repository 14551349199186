import { ElButton, ElRow, ElCol, ElIcon, ElBreadcrumb, ElBreadcrumbItem, locale } from 'element-plus'
import './element.scss'
import lang from 'element-plus/lib/locale/lang/zh-cn'
locale(lang)
export default (app) => {
  app.use(ElButton)
  app.use(ElRow)
  app.use(ElCol)
  app.use(ElIcon)
  app.use(ElBreadcrumb)
  app.use(ElBreadcrumbItem)
}
