export default [
    {
        path: 'home',
        name: 'websiteHome',
        component: () => import('../../views/website/home/Index.vue')
    }, {
        path: 'data',
        name: 'websiteData',
        component: () => import('../../views/website/data/Index.vue')
    }, {
        path: 'report',
        name: 'websiteReport',
        component: () => import('../../views/website/report/Index.vue')
    }, {
        path: 'expert',
        name: 'websiteExpert',
        component: () => import('../../views/website/expert/Index.vue')
    }, {
        path: 'expertView',
        name: 'websiteExpertView',
        component: () => import('../../views/website/expert/View.vue')
    }, {
        path: 'about',
        name: 'websiteAbout',
        component: () => import('../../views/website/about/Index.vue')
    }
]
