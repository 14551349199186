import { createRouter, createWebHistory } from 'vue-router'
import website from './website/website.js'
const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/website/home'
  },
  {
    path: '/website',
    name: 'website',
    component: () => import('../views/website/App.vue'),
    children: [
      ...website
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
