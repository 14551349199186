import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import installElementPlus from './plugins/element/element'
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import './assets/css/global.scss'
import axios from 'axios'
import qs from 'qs'
import './common/const/global.js'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.interceptors.request.use(config => {
    config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('SESSION_TOKEN')
    if (config.method === 'post') {
        if (config.data !== '' && config.data !== undefined && config.data !== null) {
            config.data += '&_t=' + Date.parse(new Date()) / 1000
        } else {
            config.data = '_t=' + Date.parse(new Date()) / 1000
        }
    } else if (config.method === 'get') {
        if (config.url.indexOf('?') !== -1) {
            config.url += '&_t=' + Date.parse(new Date()) / 1000
        } else {
            config.url += '?_t=' + Date.parse(new Date()) / 1000
        }
    }
    return config
})

const app = createApp(App)
installElementPlus(app)
app.config.globalProperties.SESSION_TOKEN = 'SESSION_TOKEN'
app.config.globalProperties.SESSION_USER = 'SESSION_USER'
app.config.globalProperties.SESSION_ROLE = 'SESSION_ROLE'
app.config.globalProperties.SESSION_MODULE = 'SESSION_MODULE'
app.config.globalProperties.CACHE_PAGE = 'CACHE_PAGE'
app.config.globalProperties.CACHE_PARAM = 'CACHE_PARAM'
app.config.globalProperties.COLOR = '#00CC99'
app.config.globalProperties.UMS_URL = 'https://server.utp.ink:10101'
app.config.globalProperties.BMS_URL = 'https://server.utp.ink:10301'
app.config.globalProperties.STATIC_URL = 'https://server.utp.ink/static'
app.config.globalProperties.$http = axios
app.config.globalProperties.$qs = qs
app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$confirm = ElMessageBox.confirm
app.config.globalProperties.$alert = ElMessageBox.alert
app.config.globalProperties.$loading = ElLoading.service
app.config.globalProperties.LOADING_OPTION = {
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
}
app.use(router).mount('#app')
